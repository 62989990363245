import { addDays, compareAsc, differenceInCalendarDays, isSameDay, parseISO, set, subMinutes } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { umbraco } from '../../../lib/api';
import { AppointmentAvailability, TimeSlot } from '../../../lib/api/models/hessel-api';
import { Holiday } from '../../../lib/api/models/umbraco';
import { dateExists, generateDates } from '../date.helper';
import { Workshop } from '../../../lib/state/booking/workshops/booking-workshops.types';
import { DeliveryType } from '../../../lib';

export function generateServiceTooLongAppointmentAvailability(
    leadTimeInDays: number,
    dateFrom: Date,
    dateTo: Date,
    serviceTooLongHolidays: Holiday[]
): AppointmentAvailability<string>[] {
    const appointmentAvailabilities: AppointmentAvailability<string>[] = [];

    const minimumLeadTimeDate = addDays(Date.now(), leadTimeInDays);
    if (dateFrom < minimumLeadTimeDate) {
        dateFrom = minimumLeadTimeDate;
    }

    const currentYear = new Date().getFullYear();
    const unavailableDates: Date[] = serviceTooLongHolidays
        .map(({ holiday }) => parseISO(holiday))
        .map((holiday) => new Date(currentYear, holiday.getMonth(), holiday.getDate()));

    for (const date of generateDates(dateFrom, differenceInCalendarDays(addDays(dateTo, 1), dateFrom))) {
        const weekDay = date.getDay();
        const available = ![0, 6].includes(weekDay) && !dateExists(date, unavailableDates);
        const times = generateLongServiceAvailableTimes(date);

        appointmentAvailabilities.push({
            date: date.toISOString(),
            timeSlots: times.map((t) => generateTimeSlot(t, available)),
        });
    }
    return appointmentAvailabilities;
}

function generateTimeSlot(date: Date, available: boolean): TimeSlot<string> {
    return {
        startDateTime: date.toISOString(),
        available: available,
        duration: '00:30:00',
    };
}

function generateLongServiceAvailableTimes(date: Date): Date[] {
    const utcDate1 = set(date, {});
    const utcDate2 = set(date, {});

    utcDate1.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    utcDate2.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    utcDate1.setUTCHours(7, 30);
    utcDate2.setUTCHours(8, 0);

    const danishLocalTimeOffset = getTimezoneOffset('Europe/Copenhagen', utcDate1) / 1000 / 60;

    const danishDate1 = subMinutes(utcDate1, danishLocalTimeOffset);
    const danishDate2 = subMinutes(utcDate2, danishLocalTimeOffset);

    return [danishDate1, danishDate2];
}

export function getLeadTimeForStore(content: umbraco.BookingStepWorkshop, storeId: number): number {
    const leadTimeForStore = content.serviceTooLongLeadTimes?.find((s) => s.sabId === storeId.toString())?.leadTime;
    const defaultLeadTime = content.serviceTooLongLeadTime;
    return leadTimeForStore ?? defaultLeadTime;
}

export const getAvailableDaysByDeliveryType = (workshop: Workshop, deliveryType: DeliveryType): AppointmentAvailability<Date>[] => {
    switch (deliveryType) {
        case DeliveryType.MobileService:
            return workshop.availableDaysMobileService;
        case DeliveryType.SelfDeliverAndPickup:
            return workshop.availableDaysSelfDeliverAndPickup;
        case DeliveryType.ServiceTooLong:
            return workshop.availableDaysServiceTooLong;
        case DeliveryType.CustomerStays:
        case DeliveryType.ByVendor:
            return workshop.availableDaysToStay;
        default:
            return [];
    }
};

export const findFirstAvailableTimeSlot = (
    timeSlots: AppointmentAvailability<Date>[],
    selectedDate: Date
): { date?: Date; timeSlot?: TimeSlot<Date> } => {
    const sortedAvailableDays = timeSlots.sort((a, b) => compareAsc(a.date, b.date));

    const dateAlreadySelectedInOtherCalendar = sortedAvailableDays?.find(
        (x) => isSameDay(x.date, selectedDate) && x.timeSlots.some((timeSlot) => timeSlot.available)
    );

    const firstDateWithAvailableTimeSlot = sortedAvailableDays?.find((x) => x.timeSlots.some((timeSlot) => timeSlot.available));
    const availableDate = dateAlreadySelectedInOtherCalendar ?? firstDateWithAvailableTimeSlot;
    const firstAvailableTimeSlot = availableDate?.timeSlots.find((ts) => ts.available);

    return {
        date: availableDate?.date,
        timeSlot: firstAvailableTimeSlot,
    };
};
