export type GetVehicleInfoParams = {
    licensePlate: string;
};

export type GetVehicleInfoResponse = VehicleInformation;

export type VehicleInformation = {
    make?: string;
    vehicleClass?: string;
    model?: string;
    variant?: string;
    fuelTypeId?: FuelType;
    preferredWorkshopId?: number;
    serviceAgreement?: string;
    hasStoredTires?: boolean;
};

export enum FuelType {
    Benzin = 1,
    Diesel = 2,
    EL = 4,
}
