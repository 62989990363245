export enum DeliveryType {
    SelfDeliverAndPickup = 0,
    CustomerStays = 1,
    /**
     * Used for el-cars
     */
    ByVendor = 2,
    MobileService = 3,
    ServiceTooLong = -1,
}

export enum MINIMAL_SITE_HEADER_HEIGHT {
    MOBILE = 70,
    DESKTOP = 100,
}
