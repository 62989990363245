import { ParsedUrlQueryInput } from 'querystring';
import { APIResponse } from '../../../lib/api';
import { getAPI } from '../../../lib/api/helpers';

let bookingSessionId: string | undefined = undefined;

export const getBookingSessionIdHeader = (): Headers => {
    const headers = new Headers();
    bookingSessionId ??= crypto.randomUUID();
    headers.append('X-Session-Id', bookingSessionId);
    return headers;
};

export const bookingGetAPI = async <Response = unknown, T extends ParsedUrlQueryInput = ParsedUrlQueryInput>(
    url: string,
    query?: T,
    signal?: AbortSignal
): APIResponse<Response> => {
    return getAPI<Response>(url, query, signal, getBookingSessionIdHeader());
};
